<template>
    <el-form :model="dataSource" status-icon :rules="pwdValidateRule" ref="_pwdInfoForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="登录名">
            <el-input v-model="UserName" placeholder="admin"
                      :disabled="true">
            </el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="NewPassword">
            <el-input type="password" v-model="dataSource.NewPassword" :maxlength="32" :minlength="8" auto-complete="off" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="ConfirmPassword">
            <el-input type="password" v-model="dataSource.ConfirmPassword" :maxlength="32" :minlength="8" auto-complete="off" placeholder="请再次输入密码"></el-input>
        </el-form-item>
        <!--<el-form-item>
            <el-button type="primary" @click="submitForm('dataSource')">提交</el-button>
            <el-button @click="resetForm('dataSource')">重置</el-button>
        </el-form-item>-->
    </el-form>
</template>
<script>
    export default {
        data() {
            var validateNewPwd = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入新密码'));
                } else {
                    if (value.length < 8) {
                        callback(new Error('密码至少八位或者八位以上'))
                    }
                    var patrn = /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)(?![a-zA-z\d]+$)(?![a-zA-z!@#$%^&*]+$)(?![\d!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/;
                    if (!patrn.exec(value)) callback(new Error('密码必须包含数字，字母，特殊字符'))

                    if (this.dataSource.ConfirmPassword !== '') {
                        this.$refs._pwdInfoForm.validateField('ConfirmPassword');
                    }
                    callback();
                }
            };

            var validateRePwd = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.dataSource.NewPassword) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                dataSource: {
                    NewPassword: '',
                    ConfirmPassword: ''
                },
                pwdValidateRule: {
                    NewPassword: [
                        { validator: validateNewPwd, trigger: 'blur' }
                    ],
                    ConfirmPassword: [
                        { validator: validateRePwd, trigger: 'blur' }
                    ]
                }
            };
        },
        props: ['UserId', 'UserName'],
        methods: {
            resetPwd(options) {
                var _this = this;
                _this.$refs["_pwdInfoForm"].validate((valid) => {
                    if (valid) {
                        var param = { UserId: _this.UserId, NewPassword: _this.dataSource.NewPassword, ConfirmPassword: _this.dataSource.ConfirmPassword };
                        param.NewPassword = _this.$crypto.sm2Encrypt(param.NewPassword);
                        param.ConfirmPassword = _this.$crypto.sm2Encrypt(param.ConfirmPassword);
                        _this.$ajax.send("omsapi/sysuser/resetpwd", "post", param, (data) => {
                            _this.Utils.messageBox("密码重置成功.", "success");
                            _this.resetForm();
                            _this.uid = '';
                        }, error => {
                            _this.Utils.messageBox(error, "error");
                            _this.resetForm();
                            options.visible = true;
                        });
                    } else {
                        options.visible = true;
                        return false;
                    }
                });
            },
            resetForm() {
                this.$refs["_pwdInfoForm"].resetFields();
            }
        }
    }
</script>
<style scoped>
</style>